import { HttpClient } from '../../helpers/httpClient'
import { beforeRequestHandler } from '../sso'

if (!process.env.VUE_APP_ORDERS_API_URL) {
  throw new Error('VUE_APP_ORDERS_API_URL not defined in .env')
}

export const ordersClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORDERS_API_URL,
  beforeRequestHandler,
})
