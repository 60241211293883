import Vue from 'vue'
import VueCompositionAPI, { computed } from '@vue/composition-api'
import { reactive, readonly } from '@vue/composition-api'
import dayjs from 'dayjs'
import { patientsClient } from './clients/patients'
import { onAuthChanged } from '../hooks'

Vue.use(VueCompositionAPI)

onAuthChanged(authUser => {
  if (!authUser) {
    _state.patient = null
    _state.patients = []
  }
})

const _state = reactive({
  patient: null,
  patients: [],
})
export const state = readonly(_state)

export const clearPatient = () => {
  _state.patient = null
}

export const assignDevice = async (patientId, kind, deviceRefId) => {
  let { data } = await patientsClient.post('/AssignDevice', {
    patientId,
    kind,
    deviceRefId,
  })
  return data.device
}

export const getPatient = async id => {
  const { data } = await patientsClient.post('/GetPatient', { id })
  _state.patient = data.patient
  return data.patient
}

export const getPatientBySms = async smsPhone => {
  try {
    const { data } = await patientsClient.post('/GetPatient', { smsPhone })
    return data.patient
  } catch (e) {
    return undefined
  }
}

export const setPatient = patient => {
  _state.patient = patient
}

export const getOrgPatient = async (orgId, orgPatientId) => {
  const { data } = await patientsClient.post('/GetPatient', {
    orgId,
    orgPatientId,
  })
  _state.patient = data.patient
  return data.patient
}

export const createPatient = async patient => {
  const { data } = await patientsClient.post('/CreatePatient', patient)
  _state.patient = data.patient
  return data.patient
}

export const updatePatientSmsPhone = async (patientId, smsPhone) => {
  const { data } = await patientsClient.post('/UpdateSmsPhone', { id: patientId, smsPhone })
  _state.patient = data.patient
  return data.patient
}

export const initials = computed(() => {
  if (_state.patient) {
    return _state.patient.firstName.substr(0, 1) + _state.patient.lastName.substr(0, 1)
  }
  return ''
})

export const fullName = computed(() => {
  if (_state.patient) {
    return [_state.patient.firstName, _state.patient.lastName].join(' ')
  }
  return ''
})

export const gender = computed(() => {
  if (_state.patient) {
    return _state.patient.gender //.substr(0, 1)
  }
  return ''
})

export const birthdate = computed(() => {
  if (_state.patient) {
    return dayjs(_state.patient.birthdate).format('MM/DD/YYYY')
  }
  return ''
})

export const address = computed(() => {
  if (_state.patient && _state.patient.address) {
    const addr = _state.patient.address
    const vals = []
    if (addr.line1) {
      vals.push(addr.line1)
    }
    if (addr.line2) {
      vals.push(addr.line2)
    }
    if (addr.city) {
      vals.push(addr.city)
    }
    if (addr.state) {
      vals.push(addr.state)
    }
    if (addr.zip) {
      vals.push(addr.zip)
    }
    return vals.join(' ')
  }
  return null
})

export const age = computed(() => {
  const today = new Date()
  if (_state.patient) {
    const birthDate = new Date(_state.patient.birthdate)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }
  return ''
})

export const smsPhone = computed(() => {
  if (_state.patient) {
    return _state.patient.smsPhone
  }
  return ''
})

export const conditions = computed(() => {
  const conds = []
  if (_state.patient) {
    _state.patient.conditions.forEach(item => {
      const slice = item.split('|')
      conds.push({
        label: slice[0],
        value: slice[1],
      })
    })
  }
  return conds
})

export const medications = computed(() => {
  const meds = []
  if (_state.patient) {
    _state.patient.medications.forEach(item => {
      const slice = item.split('|')
      meds.push({
        label: slice[0],
        value: slice[1],
      })
    })
  }
  return meds
})

export const hasMeasurements = computed(() => {
  if (_state.patient) {
    return _state.patient.rpm.lastData
  }
  return false
})

export const rpmDate = computed(() => {
  if (_state.patient) {
    return dayjs(_state.patient.rpm.lastData).format('MMM, DD YYYY')
  }
  return ''
})

export const rpmTime = computed(() => {
  if (_state.patient) {
    return dayjs(_state.patient.rpm.lastData).format('h:mm a')
  }
  return ''
})

export const updatePatient = async patient => {
  const { data } = await patientsClient.post('/Update', patient)
  return data.patient
}
