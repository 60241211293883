import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, readonly } from '@vue/composition-api'
import { onAuthChanged } from '../hooks'
import { ordersClient } from './clients/orders'

Vue.use(VueCompositionAPI)

onAuthChanged(authUser => {
  if (!authUser) {
    _state.orders = []
  }
})

const _state = reactive({
  order: null,
  orders: [],
})
export const state = readonly(_state)

export const getOrders = async (orgId, status) => {
  const { data } = await ordersClient.post('/GetOrders', {
    ownerId: orgId,
    status,
  })
  _state.orders = data.orders
  return _state.orders
}

export const createOrder = async order => {
  const { data } = await ordersClient.post('/CreateOrder', order)
  _state.order = data.order
  return _state.order
}

export const updateOrders = async orders => {
  await ordersClient.post('/UpdateOrders', { orders })
}

export const deleteOrders = async ids => {
  await ordersClient.post('/DeleteOrders', ids)
}

export const searchOrders = async (text, orgId) => {
  const { data } = await ordersClient.post('/SearchOrders', {
    text,
    ownerId: orgId,
  })
  _state.orders = data.orders
  return _state.orders
}

export const markDevicesAsRegistered = async devices => {
  await ordersClient.post('/MarkDevicesAsRegistered', devices)
}
